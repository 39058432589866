import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { withStyles } from "@material-ui/core/styles";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Img from 'gatsby-image'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { FaCalendarDay } from '@react-icons/all-files/fa/FaCalendarDay'
import {BsFillPersonFill} from '@react-icons/all-files/bs/BsFillPersonFill'
import { BLOCKS } from '@contentful/rich-text-types';

const styles = theme => ({
    root: {
        width: '100%'
    },
    imgWrapper: {
        width: '100%',
        
        

    },
    image: {
        width: '100%',
        maxHeight:'600px'
        
    },
    headerWrapper: {
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(3)
    },
    textLeftMargin: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    

})

const useStyles = makeStyles(theme => ({
    blogHeader: theme.typography.blogHeader
}))

const HeroImg = ({ imgWrapperClass, fluid, imageClass }) => {
    return (
        <Box className={imgWrapperClass}>
            <Img fluid={fluid} className={imageClass} />
        </Box>
    )
}

const Header = ({ title }) => {
    const classes = useStyles()
    return (
        <Typography component='h1' className={classes.blogHeader}>{title}</Typography>
    )
}

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Typography variant='body1' paragraph>{children}</Typography>,
      },
}

class BlogPost extends React.Component {
    render() {

        const locale = this.props.pageContext.nodeLocale
        const { classes, location } = this.props;
        const blogPost = get(this.props.data, 'contentfulBlogYayini')
        const imgName = blogPost.heroImage?.localFile.childImageSharp.fluid
        const blogMeta = {headline:blogPost.title, url:location, datePublished:blogPost.Date, description:blogPost.subtitle, author:blogPost.author}

        return (
            <Layout nodeLocale={locale} location={location}>
                <SEO title={blogPost.title} lang={locale} location={location} description={blogPost.subTitle} blogMeta={blogMeta}/>

                {imgName && <HeroImg fluid={imgName} imgWrapperClass={classes.imgWrapper} imageClass={classes.image} />}
                <Box className={classes.headerWrapper}>
                    <Header title={blogPost.title} />
                    <Typography variant='body1' >{blogPost.subTitle}</Typography>
                   
                    <div >
                        <FaCalendarDay />
                        <Typography variant="subtitle2" color="textSecondary" component='span' className={classes.textLeftMargin}>
                        {new Intl.DateTimeFormat(locale).format(new Date(blogPost.publishDate))}
                        </Typography>
                        <BsFillPersonFill />
                        {blogPost.author && <Typography variant="subtitle2" color="textSecondary" component='span' className={classes.textLeftMargin}>
                            {blogPost.author}
                        </Typography>}
                    </div>
                </Box>
                <div >
                    {documentToReactComponents(JSON.parse(blogPost.body.raw),options)}
                </div>
            </Layout>
        )
    }
}


export default withStyles(styles, { withTheme: true })(BlogPost)
export const query = graphql`
 query BlogPostQuery($blogId: String, $nodeLocale: String)
 {
    contentfulBlogYayini(id: {eq: $blogId}, node_locale: {eq: $nodeLocale}) {
        body {
          raw
        }
        id
        node_locale
        publishDate
        slug
        subTitle
        title
        author
        publishDate
        heroImage {
            id
            title
            description
            localFile {
                childImageSharp {
                    fluid (maxWidth:1920, quality:90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            
            

        }
      }
    }
`